<template>
    <div class="form-select-search-wrap">
        <div class="input-group">
            <input
                type="text"
                v-model="display"
                class="form-control"
                autocomplete="off"
                @focus="focusIn"
                @blur="focusOut"
                @keyup="changeText"
                @keydown.down="pressDown"
                @keydown.up="pressUp"
                @keydown.enter.prevent="pressEnter"
                @compositionstart="composing = true"
                @compositionend="composing = false"
                :required=required
                :placeholder="placeholder"
                id="selectSearchInput"
            >
            <div class="input-group-text" style="cursor: pointer;" @click="resetInput"><i class="bi bi-eraser-fill"></i></div>
        </div>
        <div class="form-select-search-options" v-if="show_options">
            <template v-for="option in filtered_options" :key="option.key">
                <button
                    type="button"
                    name="form-select-search-option"
                    :value="option.key"
                    :class="{'form-select-search-option-active': option.active, 'form-select-search-option': true}"
                    tabindex="-1"
                >{{ option.label }}</button>
            </template>
            <template v-if="filtered_options.length === 0">
                <div class="form-select-search-option">該当候補なし</div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FormSelectSearch',
    data() {
        return {
            //オプション表示中
            show_options: false,
            //検索中（テキストを変更〜選択前）
            searching: false,
            //変換中
            composing: false,

            display: null,
            display_before: null,
            filtered_options: [],
        }
    },
    props: {
        modelValue: {},
        options: {
            type: Array
        },
        option_key: {
            type: String,
            default: 'key'
        },
        option_label: {
            type: String,
            default: 'label'
        },
        placeholder: {
            type: String,
            default: null
        },
        required: {
            type: Boolean,
            default: false
        }

    },
    emits: [
        'update:modelValue'
    ],
    mounted() {
        this.setDisplay();
    },
    watch: {
        modelValue: function () {
            this.setDisplay();
        },
    },
    methods: {
        //valueが変わった時にテキストを変える (mounted と watch 、emit 時に呼ばれる)
        setDisplay() {
            if (this.$helper.isNud(this.modelValue)) {
                this.display = null;
                this.display_before = null;
                return;
            }

            let selected_option = this.options.find((option) => {
                return option[this.option_key] === parseInt(this.modelValue, 10);
            })

            if (typeof selected_option === 'undefined') {
                this.display = this.modelValue;
                this.display_before = this.modelValue;
                return;
            }

            this.display = selected_option[this.option_label];
            this.display_before = selected_option[this.option_label];
        },
        //選択肢を絞り込む (focusIn と changeText から呼ばれる)
        filterOption() {
            this.filtered_options = [];
            this.options.forEach((option) => {
                if (this.display === null || option[this.option_label].indexOf(this.display) >= 0) {
                    this.filtered_options.push({
                        key: option[this.option_key],
                        label: option[this.option_label],
                        active: false,
                    });
                }
            });
            if (this.filtered_options.length > 0) {
                //1行目をactiveにする
                this.filtered_options[0].active = true;
            }
        },
        // 入力情報を消す
        resetInput() {
            this.display = null;
            this.$emit('update:modelValue', null);
        },
        //テキスト入力時
        changeText() {
            //変換中は無視
            if (this.composing) {
                return;
            }
            //変わってなければ無視
            if (this.display === this.display_before) {
                return;
            }

            //選択肢を絞り込み
            this.display_before = this.display;
            this.filterOption();
            this.searching = true;
        },
        //フォーカス時
        focusIn() {
            this.filterOption();
            this.show_options = true;
        },
        //フォーカスアウト時
        focusOut(e) {
            if (e.relatedTarget && e.relatedTarget.name === 'form-select-search-option') {
                //選択によってフォーカスが外れたとき
                this.$emit('update:modelValue', e.relatedTarget.value);
                this.setDisplay();
            } else {
                //それ以外でフォーカスが外れたとき
                if (this.searching) {
                    //テキストが変更されていたら、値を空にする
                    //TODO:変換中に無理やりフォーカスを外されたときに対応できていない
                    this.$emit('update:modelValue', null);
                    this.setDisplay();
                }
            }
            this.show_options = false;
            this.searching = false;
        },
        pressDown() {
            //変換中の↓キーは無視
            if (this.composing) {
                return;
            }
            //次の候補をアクティブにする
            if (this.filtered_options.length === 0) {
                return;
            }
            let active_index = this.filtered_options.findIndex((option) => {return option.active});
            if (active_index >= 0 && active_index < this.filtered_options.length - 1) {
                this.filtered_options[active_index].active = false;
                this.filtered_options[active_index + 1].active = true;
            }
        },
        pressUp() {
            //変換中の↑キーは無視
            if (this.composing) {
                return;
            }
            //前の候補をアクティブにする
            if (this.filtered_options.length === 0) {
                return;
            }
            let active_index = this.filtered_options.findIndex((option) => {return option.active});
            if (active_index >= 1) {
                this.filtered_options[active_index].active = false;
                this.filtered_options[active_index - 1].active = true;
            }
        },
        pressEnter() {
            //変換中のENTERキーは無視
            if (this.composing) {
                return;
            }
            //アクティブな選択肢があればそれを選択する
            let active_option = this.filtered_options.find((option) => {return option.active});
            if (typeof active_option !== 'undefined') {
                this.$emit('update:modelValue', active_option.key);
                this.setDisplay();
                this.searching = false;
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-select-search-wrap {position: relative;}
.form-select-search-options {position: absolute; top: 100%; left: 0; width: 100%; max-height: 200px; overflow-y: scroll; background: #EEE; z-index: 1000;}
.form-select-search-option {display: block; width: 100%; padding: 5px 10px; border: none; background: none; text-align: left;}
.form-select-search-option-active {background: #DDD;}
</style>
